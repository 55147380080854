<template>
    <div size="A4" class="page">
        <div v-if="loading">
            <Loading />
        </div>
        <!-- <b-overlay :show="loading"> -->
        <template v-else>
            <div class="text-right">
                <b-button @click="pdfExport" variant="primary" class="mb-3" size="sm">
                    <i class="ri-printer-fill"></i>
                    {{ $t('teaGardenConfig.Print') }}
                </b-button>
            </div>
            <div style="border: 1px double #222; padding: 1rem; height: 100%">
                <!-- <TeaGardenReportHead :baseUrl="teaGardenServiceBaseUrl" :uri="'/configuration/report-heading/detail'" :orgId="5" :loadingState="false">
                    {{ $t('teaGardenService.small_tea_farmer_certificate_title') + ' ' + $t('globalTrans.certificate') }}
                </TeaGardenReportHead> -->
                <div class="text-center" style="text-decoration: underline;">{{ $t('lrcpnServicePanel.formalin_clearance_application') }} {{ $t('lrcpnServicePanel.certificate') }}</div>
                <br>
                <div class="mb-4" style="display: flex; flex-wrap: wrap;">
                    <div class="" style="width:50%"><b>{{ $t('globalTrans.application_id') }}</b> : {{ appDetail.application_id ? EngBangNum(appDetail.application_id) : '' }}</div>
                    <div class="text-right" style="width:50%"><b>{{ $t('globalTrans.date') }}</b> : {{ appDetail.approved_date | dateFormat }}</div>
                </div>
                <div class="mb-3">
                    <p>
                        {{ currentLocale === 'en'
                        ? 'Subject to the provisions of the Formalin (Import, Production, Transportation, Storage, Sale, and Use) Control Rules, 2015, and the conditions mentioned in this permit, the following individual/institution/organization is granted a permit for the import of formalin based on their application dated ' + EngBangNum(formattedDate)
                        : 'ফরমালিন (আমদানি, উৎপাদন, পরিবহন, মজুদ, বিক্রয় ও ব্যবহার) নিয়ন্ত্রণ বিধিমালা, ২০১৫ এর বিধানাবলী এবং এই ছাড়পত্রে উল্লিখিত শর্তাবলী সাপেক্ষে নিম্নবর্ণিত ব্যক্তি/প্রতিষ্ঠান/সংস্থাকে তাঁর ' + EngBangNum(formattedDate) + ' তারিখের আবেদনের প্রেক্ষিতে ফরমালিন আমদানির ছাড়পত্র প্রদান করা হইল।' }}
                    </p>
                </div>
                <table class="report-info">
                    <tbody>
                        <tr>
                            <td style="width: 3%;">{{ $n(1) }}</td>
                            <td style="width: 2%">{{ ' | ' }}</td>
                            <td style="width: 40%;">{{ $t('lrcpnServicePanel.name_institution') }}</td>
                            <td style="width: 5%;">&emsp;:&nbsp;</td>
                            <td style="width: 50%;"> {{ currentLocale === 'en' ? appDetail.applicant_name_en : appDetail.applicant_name_bn }} </td>
                        </tr>
                        <tr>
                            <td style="width: 3%;">{{ $n(2) }}</td>
                            <td style="width: 2%">{{ ' | ' }}</td>
                            <td style="width: 40%;">{{ $t('lrcpnServicePanel.name_owner') }}</td>
                            <td style="width: 5%;">&emsp;:&nbsp;</td>
                            <td style="width: 50%;"> {{ currentLocale === 'en' ? appDetail.applicant_name_en : appDetail.applicant_name_bn }} </td>
                        </tr>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(3) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 40%; vertical-align: top;">{{ currentLocale === 'en' ? ' (A) ' : ' (ক) ' }}{{ $t('lrcpnServicePanel.name_owner_nid') }}</td>
                            <td style="width: 5%; vertical-align: top;">&emsp;:&nbsp;</td>
                            <td style="width: 50%; vertical-align: top;" v-if="appDetail.applicant_nid">{{  EngBangNum(appDetail.applicant_nid) }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%;"></td>
                            <td style="width: 2%"></td>
                            <td style="width: 40%;">{{ currentLocale === 'en' ? '(B) ' : '(খ) ' }}{{ $t('globalTrans.address') }}</td>
                            <td style="width: 5%;">&emsp;:&nbsp;</td>
                            <td style="width: 50%;" v-if="appDetail.business_info?.address_bn"> {{ currentLocale === 'en' ? appDetail.business_info?.address_en : appDetail.business_info?.address_bn }} </td>
                        </tr>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(4) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 40%; vertical-align: top;">{{ $t('lrcpnServicePanel.formalin_details') }}</td>
                            <td style="width: 5%; vertical-align: top;">&emsp;:&nbsp;</td>
                            <td  style="width: 50%;" v-if="appDetail.business_info?.import_amounts_info">
                                <b-table-simple style="width: 100%" bordered>
                                    <b-tr>
                                        <b-th class="text-center" style="width: 50%;"> {{ $t('lrcpnServicePanel.row_material_name') }}</b-th>
                                        <b-th class="text-center" style="width: 25%;"> {{ $t('lrcpnServicePanel.qty') }}</b-th>
                                        <b-th class="text-center" style="width: 25%;"> {{ $t('lrcpnServicePanel.price') }}</b-th>
                                    </b-tr>
                                    <template v-if="appDetail.business_info.import_amounts_info?.length">
                                        <slot v-for="(item, index) in appDetail.business_info?.import_amounts_info">
                                            <b-tr :key="index">
                                                <b-td class="text-center">
                                                    {{ currentLocale === 'en' ? item.row_material_name_en : item.row_material_name_bn }}
                                                </b-td>
                                                <b-td class="text-center">
                                                    {{ $n(item.qty, {useGrouping: false}) }}
                                                </b-td>
                                                <b-td class="text-center">
                                                    {{ $n(item.price, {useGrouping: false}) }}
                                                </b-td>
                                            </b-tr>
                                        </slot>
                                        <b-tr>
                                            <b-td colspan="2" class="text-right">{{$t('globalTrans.total')}}</b-td>
                                            <b-td class="text-center">{{ $n(totalAmount) }}</b-td>
                                        </b-tr>
                                    </template>
                                    <template v-else>
                                        <b-tr>
                                            <b-td colspan="3" class="text-center">{{ $t('national_award.no_data_added') }}</b-td>
                                        </b-tr>
                                    </template>
                                </b-table-simple>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 3%;">{{ $n(5) }}</td>
                            <td style="width: 2%">{{ ' | ' }}</td>
                            <td style="width: 40%;">{{ $t('lrcpnServicePanel.purpose_formalin') }}</td>
                            <td style="width: 5%;">&emsp;:&nbsp;</td>
                            <td style="width: 50%;" v-if="appDetail.business_info?.present_import_details?.import_purpose"> {{ appDetail.business_info?.present_import_details?.import_purpose }} </td>
                        </tr>
                        <tr>
                            <td style="width: 3%;">{{ $n(6) }}</td>
                            <td style="width: 2%">{{ ' | ' }}</td>
                            <td style="width: 40%;">{{ $t('lrcpnServicePanel.formalin_value') }}</td>
                            <td style="width: 5%;">&emsp;:&nbsp;</td>
                            <td style="width: 50%;" v-if="appDetail.business_info?.import_amounts_info"> {{ $n(totalAmount) }} </td>
                        </tr>
                        <tr>
                            <td style="width: 3%;">{{ $n(7) }}</td>
                            <td style="width: 2%">{{ ' | ' }}</td>
                            <td style="width: 40%;">{{ $t('lrcpnServicePanel.name_address') }}</td>
                            <td style="width: 5%;">&emsp;:&nbsp;</td>
                            <td style="width: 50%;" v-if="appDetail.business_info?.import_helping_agent_name"> {{ (appDetail.business_info?.import_helping_agent_name) }}, {{ (appDetail.business_info?.import_helping_agent_address) }} </td>
                        </tr>
                        <tr>
                            <td style="width: 3%;">{{ $n(8) }}</td>
                            <td style="width: 2%">{{ ' | ' }}</td>
                            <td style="width: 40%;">{{ $t('lrcpnServicePanel.port_entry') }}</td>
                            <td style="width: 5%;">&emsp;:&nbsp;</td>
                            <td style="width: 50%;" v-if="appDetail.business_info?.present_import_details?.import_port_name"> {{ (appDetail.business_info?.present_import_details?.import_port_name) }} </td>
                        </tr>
                        <tr>
                            <td style="width: 3%;">{{ $n(9) }}</td>
                            <td style="width: 2%">{{ ' | ' }}</td>
                            <td style="width: 40%;">{{ $t('lrcpnServicePanel.expiration_license') }}</td>
                            <td style="width: 5%;">&emsp;:&nbsp;</td>
                            <td style="width: 50%;">  </td>
                        </tr>
                    </tbody>
                </table>
                <strong style="margin-top:2rem; display: block;">{{ $t('teaGardenPanel.conditions') }} :</strong>
                <!-- <div class="text-justify" v-html="currentLocale === 'en' ? application.application.service_instruction.instruction_en : application.application.service_instruction.instruction_bn"></div> -->
                <table style="width:100%; margin-top: 2rem;">
                    <tbody>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(1) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 95%;">{{ currentLocale === 'en' ? 'This permit shall not be transferable.' : 'এই ছাড়পত্র হস্তান্তরযোগ্য হইবে না।' }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(2) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 95%;">{{ currentLocale === 'en' ? 'This permit may not be used more than once.' : 'এই ছাড়পত্র একবারের বেশী ব্যবহার করা যাইবে না।' }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(3) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 95%;">{{ currentLocale === 'en' ? 'Formalin or its components or materials imported under this permit shall not be used for any purpose other than the purpose specified in this permit.' : 'এই ছাড়পত্রের মাধ্যমে আমদানীকৃত ফরমালিন বা উহার উপাদান বা উপকরণ এই ছাড়পত্রে উল্লিখিত উদ্দেশ্য ব্যতীত অন্য কোন উদ্দেশ্যে ব্যবহার করা যাইবে না।' }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(4) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 95%;">{{ currentLocale === 'en' ? 'Importer shall bear all financial and legal responsibilities for import through this clearance.' : 'এই ছাড়পত্রের মাধ্যমে আমদানির ক্ষেত্রে যাবতীয় আর্থিক ও আইনগত দায়-দায়িত্ব আমদানিকারককে বহন করিতে হইবে।' }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(5) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 95%;">{{ currentLocale === 'en' ? 'Routes and entry ports other than those specified in this permit shall not be used.' : 'এই ছাড়পত্রের বর্ণিত রুট এবং প্রবেশ বন্দর ব্যতীত অন্য কোন রুট বা বন্দর ব্যবহার করা যাইবে না।' }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(6) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 95%;">{{ currentLocale === 'en' ? 'If this permit is not used within the specified time, it will be considered void.' : 'নির্ধারিত সময়ের মধ্যে এই ছাড়পত্র ব্যবহার করা না হইলে উহা বাতিল বলিয়া গণ্য হইবে।' }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(7) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 95%;">{{ currentLocale === 'en' ? 'The issuing authority may renew it upon showing a valid reason. However, no permit shall be renewed more than once.' : 'উপযুক্ত কারণ দর্শাইলে প্রদানকারী কর্তৃপক্ষ উহা নবায়ন করিতে পারিবে। তবে একবারের বেশী কোন ছাড়পত্র নবায়ন করা যাইবে না।' }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(8) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 95%;">{{ currentLocale === 'en' ? "If any formalin is to be imported through this permit, the Formalin Control Act, 2015 (Act No. 5 of 2015) and the Formalin (Import, Production, Transport, Stock, Sale and Use) Control Rules, 2015, besides the Government's Foreign Exchange Policy, Import Policy Order and Customs , Excise and VAT related policies and rules shall be duly followed." : 'এই ছাড়পত্রের মাধ্যমে কোন ফরমালিন আমদানি করিতে হইলে ফরমালিন নিয়ন্ত্রণ আইন, ২০১৫ (২০১৫ সনের ৫নং আইন) এবং ফরমালিন (আমদানি, উৎপাদন, পরিবহন, মজুদ, বিক্রয় ও ব্যবহার) নিয়ন্ত্রণ বিধিমালা, ২০১৫ ছাড়াও সরকারের বৈদেশিক মুদ্রা সংক্রান্ত নীতিমালা, আমদানি নীতি আদেশ এবং শুল্ক, আবগারী ও ভ্যাটসংক্রান্ত নীতি ও বিধিমালা যথাযথভাবে অনুসরণ করিতে হইবে।' }}</td>
                        </tr>
                        <tr>
                            <td style="width: 3%; vertical-align: top;">{{ $n(9) }}</td>
                            <td style="width: 2%; vertical-align: top;">{{ ' | ' }}</td>
                            <td style="width: 95%;">{{ currentLocale === 'en' ? 'For any reasonable cause, the issuing authority may cancel, withdraw, or temporarily suspend this permit without providing any reason.' : 'যে-কোন যুক্তিসংগত কারণে এই ছাড়পত্র কোনরূপ কারণ দর্শানো ব্যতিরেকে উহার প্রদানকারী কর্তৃপক্ষ বাতিল, প্রত্যাহার বা সাময়িকভাবে স্থগিত রাখিতে পারিবে।' }}</td>
                        </tr>
                    </tbody>
                </table>
                <!-- aproved info -->
                <table style="margin-top: 3rem;">
                    <tr>
                        <td style="width: 45%; text-align:justify; vertical-align: top;">
                            {{ $t('globalTrans.date') }} : <span style="text-decoration-style: dotted; text-decoration-line: underline;">
                                {{ appDetail.approved_date | dateFormat }}
                            </span>
                        </td>
                        <td style="width: 20%; text-align: center; padding-right: 10px; padding-left: 10px; vertical-align: top;">
                        </td>
                        <td style="width: 35%; margin-left: 10px; padding-left: 10px; vertical-align: top;">
                            <div>
                                {{ $t('lrcpnServicePanel.seal_authority') }} <br><br>
                                <p v-if="appDetail.approver_name">{{ $t('globalTrans.name') }} :
                                    <span style="text-decoration-style: dotted; text-decoration-line: underline;">{{ currentLocale === 'en' ? appDetail.approver_name : appDetail.approver_name_bn }}</span>
                                </p>
                                <p v-if="appDetail.designation_id">{{ $t('globalTrans.designation') }} :
                                   {{ getDesignationName(appDetail.designation_id) }}
                                </p>
                                <p v-if="appDetail.office_id">{{ $t('globalTrans.office') }} :
                                   {{ getOfficeName(appDetail.office_id) }}
                                </p>
                                <!-- <p>{{ $t('globalTrans.date') }} : <span
                                    style="text-decoration-style: dotted; text-decoration-line: underline;">{{ appDetail.approved_date | dateFormat }}</span>
                                </p> -->
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="text-left mt-3">
                <b-button @click="back" class="ml-3"><i class="ri-arrow-go-back-fill"></i>  {{ $t('teaGardenConfig.back') }}</b-button>
            </div>
        </template>
        <!-- </b-overlay> -->
    </div>
</template>
  <script>
  import RestApi, { lrcpnServiceBaseUrl } from '@/config/api_config'
  import { formalinImportClearaneCertificateApi } from '../../api/routes'
  import ModalBaseMasterList from '@/mixins/list'
  import Loading from './loading/Details.vue'
  export default {
    mixins: [ModalBaseMasterList],
    components: {
      Loading
    },
    data () {
      return {
        lrcpnServiceBaseUrl: lrcpnServiceBaseUrl,
        sortBy: '',
        sortDesc: true,
        sortDirection: 'desc',
        search: {
          company_name: '',
          limit: 10
        },
        items: [],
        profile: this.$store.state.ExternalUserService.eBizPanel.associationProfile,
        associationInfoList: [],
        warehouseList: [],
        viewitemId: 0
      }
    },
    created () {
      this.loadData()
    },
    watch: {
    },
    computed: {
        loading: function () {
            return this.$store.state.commonObj.loading
        },
        currentLocale () {
          return this.$i18n.locale
        },
        formattedDate () {
          return this.appDetail.created_at.slice(0, 10)
        },
        typeOfBusinessList () {
          return this.$store.state.ExternalUserService.eBizPanel.commonObj.typeOfBusinessList.filter(item => item.status === 1)
        },
        appId () {
          return this.$route.params.id
        },
        masterAcidNameList () {
          if (this.$store.state.Auth.authUser.user_type === 2) {
            return this.$store.state.ExternalUserService.lrcpnPanel.commonObj.masterAcidNameList.filter(item => item.status === 1)
          } else {
            return this.$store.state.LrcpnService.commonObj.masterAcidNameList.filter(item => item.status === 1)
          }
        },
        totalAmount () {
            let total = 0
            this.appDetail.business_info.import_amounts_info.forEach(item => {
                total += item.price
            })
            return total
        },
        divisionList () {
            return this.$store.state.CommonService.commonObj.divisionList.filter(item => item.status === 1)
        },
        districtList () {
            return this.$store.state.CommonService.commonObj.districtList.filter(item => item.status === 1)
        },
        cityCorporationList () {
            return this.$store.state.CommonService.commonObj.cityCorporationList.filter(item => item.status === 1)
        },
        upazilaList () {
            return this.$store.state.CommonService.commonObj.upazilaList.filter(item => item.status === 1)
        },
        municipalityList () {
            return this.$store.state.CommonService.commonObj.municipalityList.filter(item => item.status === 1)
        },
        unionList () {
            return this.$store.state.CommonService.commonObj.unionList.filter(item => item.status === 1)
        },
        officeList () {
            return this.$store.state.CommonService.commonObj.officeList.filter(item => item.status === 1)
        },
        designationList () {
            return this.$store.state.CommonService.commonObj.designationList.filter(item => item.status === 1)
        },
        areaTypeList () {
            return this.$store.state.commonObj.areaTypeList
        }
      },
    methods: {
        getTypeOfBussiness (businessId) {
              const cateObj = this.$store.state.ExternalUserService.eBizPanel.commonObj.typeOfBusinessList.filter(item => item.status === 1).find(item => item.value === businessId)
              if (cateObj !== undefined) {
                  if (this.$i18n.locale === 'bn') {
                      return cateObj.text_bn
                  } else {
                      return cateObj.text_en
                  }
              }
        },
        EngBangNum (n) {
              if (this.$i18n.locale === 'bn') {
                  return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
              } else {
                  return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
              }
          },
      searchData () {
        this.loadData()
      },
      async loadData () {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        const result = await RestApi.getData(lrcpnServiceBaseUrl, formalinImportClearaneCertificateApi + '/' + this.appId)
        if (result.success) {
          this.appDetail = result.data
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false })
      },
  async pdfExport () {
    this.loading = true
    const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 1, app_id: this.appId })
    this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
    const result = await RestApi.getPdfData(lrcpnServiceBaseUrl, formalinImportClearaneCertificateApi + '/' + this.appId, params)
    var blob = new Blob([result], {
      type: 'application/pdf'
    })
    this.loading = false
    var url = window.URL.createObjectURL(blob)
    window.open(url).print()
    this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
  },
      getAcidName (id) {
          const obj = this.masterAcidNameList.find(item => parseInt(item.value) === parseInt(id))
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getOfficeName (id) {
          const obj = this.officeList.find(item => parseInt(item.value) === parseInt(id))
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getDesignationName (id) {
          const obj = this.designationList.find(item => parseInt(item.value) === parseInt(id))
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getAreaTypeList (id) {
          const obj = this.areaTypeList.find(item => parseInt(item.value) === parseInt(id))
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getDivisionName (id) {
          const obj = this.divisionList.find(item => parseInt(item.value) === parseInt(id))
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getDistrictList (id) {
          const obj = this.districtList.find(item => parseInt(item.value) === parseInt(id))
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getCityCorporationList (id) {
          const obj = this.cityCorporationList.find(item => parseInt(item.value) === parseInt(id))
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getUpazilaList (id) {
          const obj = this.upazilaList.find(item => parseInt(item.value) === parseInt(id))
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getMunicipalityList (id) {
          const obj = this.municipalityList.find(item => parseInt(item.value) === parseInt(id))
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      getUnionList (id) {
          const obj = this.unionList.find(item => parseInt(item.value) === parseInt(id))
          return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
      },
      back () {
        this.$router.go(-1)
      }
    }
  }
  </script>
<style scoped>
.page {
    background: white;
    padding: .5in;
    margin: 0 auto;
    margin-bottom: 0.5cm;
}

.page[size="A4"] {
    width: 21cm;
    min-height: 29.7cm;
    height: auto;
}

.report-table {
    margin-top: 1rem;
    width: 100%;
}

.report-table td,
.report-table th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 4px;
}

@media print {

    body,
    .page {
        margin: 0;
        box-shadow: 0;
    }
}
</style>
